/*  TODO: is it better to have rem for padding/margin units or px or depends?  asdfjklasl;dfjk*/

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: rgb(34, 34, 34);
    background-image: none;
    font-family: 'Dosis', sans-serif;
}

/* -------------------------
    general page body css
---------------------------- */

.page-body-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.page-body {
    border: none;
    padding: 50px;
    color: #e0ac00;
    background-color: #444;
    box-shadow: 0.53rem 0.848rem 1rem 0rem #1f1f1f;
    width: 78%;
    min-height: 100vh;
}

/* -------------
    navbar css
---------------- */
.nav {
    background-color: rgb(20, 20, 20);
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

/* Used inside the "<Link>" tags in the navbar */
.nav a {
    color: white;
    padding: .75rem;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    font-family: 'Dosis', sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
}


/* NOTE: !!! keep active above hover so hover changes color */
.nav li {
    border-bottom: 3px solid transparent;
}

.nav li.active {
    border-bottom: 3px solid red;
    background-color: #e0ac00;
}

.nav li:hover {
    border-bottom: 3px solid white;
    background-color: grey;
    transition: 0.4s;
}

.nav li.active:hover {
    border-bottom: 3px solid red;
    background-color: grey;
    transition: 0.4s;
}

/* -------------
    footer css
---------------- */

.zaurax-footer {
    background-color: rgb(20, 20, 20);
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    color: #ffffff;
    font-size: 1.05rem;
}

/* "Contact:" */
.zaurax-footer h3 {
    padding: 0 .5rem;
}

/* contact icon buttons */
.zaurax-footer a {
    color: #ffffff;
    padding: 19px 5px;
    font-size: 1.5rem;
}

.zaurax-footer a:hover {
    -webkit-animation: fa-bounce 1.5s infinite linear;
    -moz-animation: fa-bounce 1.5s infinite linear;
    -o-animation: fa-bounce 1.5s infinite linear;
    animation: fa-bounce 1.5s infinite linear;
}
