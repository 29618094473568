.slider-div {
    display: flex;
    flex-direction: row;
}

.home-slider {
    width: 65%;
}

.CText {
    width: 35%;
    background-color: black;
}

.Sdot {
    background-color: white;
    border: white;
    border-radius: 15%;
    width: 20%;
    height: 20%;
    padding: 2px;
    margin: 0 5px;
}

.Sdot:hover {
    background-color: rgba(255, 208, 0, 0.527);
    transition: 0.4s;
    cursor: pointer;
}

.Sdot:active {
    background-color: rgb(148, 126, 0);
}
